.footer{
  padding: 40px 0;
  // text-align: center;
  background-color: #44B5E5;
  color: #fff;
  // font-size: 28px;
  // font-weight: 500;
  // margin-bottom: 0;
  width: 100%;
  h5{
    font-size: 22px;
    font-weight: 700;
  }
  ul {
    li {
      margin-bottom: 12px;
      a {
        color: #fff!important;
        &:hover{
          text-decoration: underline!important;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px){
  // .footer{
  //   font-size: 20px;
  // }
}