.serviceCol{
  margin-bottom: 20px;
}

.serviceItems{
  padding: 15px 20px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 5px 5px 0 rgba(10, 6, 108, 0.2);
}
.serviceItems .serviceTitle{
  min-height: 68px;
  max-height: 68px;
}
.serviceItems .serviceTitle h3{
  color: var(--title-color-dark-blue);
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  margin-top: auto;
}

.serviceItems .description{
  /* height: 72px;
  max-height: 72px; */
  margin-bottom: 10px;
  
}

.serviceItems .serviceButton{
  padding: 6px 10px;
  background-color: #21ACD0;
  color: #fff!important;
  border: 1px solid #21ACD0;
  /* box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.8); */
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
}

.serviceItems .serviceButton:hover{
  background-color: #fff;
  color: #000!important;
  border: 1px solid #21ACD0;
  box-shadow: none;
}

.serviceItems .serviceImg{
  text-align: center;
  margin-bottom: 20px;
  margin-top: auto;
}

@media only screen and (min-width: 769px) and (max-width: 1200px){
  .serviceItems .serviceTitle{
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px){
  .serviceItems .custom-margin{
    margin-bottom: 60px;
  }
}