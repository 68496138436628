.newsCardWrapper{
  width: 100%;
  height: 100%;
  img{
    transition: 200ms all ease-in;
  }
  .newsCreatedAt{
    color: var(--title-color-blue);
    small {
      font-weight: 500;
    }
  }
  .newsCardTitle{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 54px;
  }
  .newsCardDesc{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    min-height: 72px;
  }
  &:hover{
    img{
      transform: scale(1.2);
      transition: 200ms all ease-in;
    }
    .readMore{
      span{
        color: var(--title-color-darken-blue);
        font-weight: 500;
      }
    }
  }
}