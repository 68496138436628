.ldsRipple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      position: absolute;
      border: 4px solid #000;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      &:nth-child(2){
          animation-delay: -0.5s;
      }
    }
}

@keyframes lds-ripple {
    0% {
      top: 40px;
      left: 40px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 80px;
      height: 80px;
      opacity: 0;
    }
}