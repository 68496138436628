.news{
    padding-top: 40px;
    padding-bottom: 80px;
    h2{
      color: var(--title-color-dark-blue);
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 40px;
    }
}

@media only screen and (max-width: 576px){
    .news{
        h2{
          font-size: 24px;
        }
    }
}